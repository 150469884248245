import { Component, OnInit } from '@angular/core';
declare const myTest: any;

@Component({
  selector: 'app-event-page',
  templateUrl: './event-page.component.html',
  styleUrls: ['./event-page.component.scss', '../app.component.scss']
})
export class EventPageComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
