<footer class="footer">
  <div class="left">
      <a id="link" href="https://www.facebook.com/SNOICEDUP/" target="_blank">Parkersburg location Facebook page</a>
  </div>
  <div class="right">
    <p>
    Parkersburg, WV
    <br>
    Sno Biz Phone Number: 304-588-1494
    <br>
    Email: snobizicedup@gmail.com
    </p>
  </div>
</footer>