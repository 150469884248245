<div class="container">
  <div class="jumbotron">
    <h1 class="text-center">Full Sno Biz Menu</h1>
  </div>
  <div class="groups">
    <div class="card-group">
      <div class="card">
        <img class="card-img-top" src="assets/images/shavedice.png" alt="Shaved Ice">
        <div class="card-body">
          <h5 class="card-title">Shaved Ice</h5>
          <p class="card-text">Small $3.75 <br> Medium $4.25 <br> Large $4.75 <br> Extra Large $5.25</p>
        </div>
      </div>
      <div class="card">
        <img class="card-img-top" src="assets/images/flowercup.png" alt="Flower Cup Shaved Ice">
        <div class="card-body">
          <h5 class="card-title">Flower Cup Shaved Ice</h5>
          <p class="card-text">$3.85</p>
        </div>
      </div>
      <div class="card">
        <img class="card-img-top" src="assets/images/shake.png" alt="Shake">
        <div class="card-body">
          <h5 class="card-title">Shake</h5>
          <p class="card-text">Small $4.25 <br> Large $4.75</p>
        </div>
      </div>
      <div class="card">
        <img class="card-img-top" src="assets/images/smoothie.png" alt="Smoothie">
        <div class="card-body">
          <h5 class="card-title">Smoothie</h5>
          <p class="card-text">Small $5.25 <br> Large $5.75</p>
        </div>
      </div>
    </div>
    <div class="card-group">
      <div class="card">
        <img class="card-img-top" src="assets/images/icedcoffee.png" alt="Iced Coffe">
        <div class="card-body">
          <h5 class="card-title">Iced Coffe</h5>
          <p class="card-text">Small $5.25 <br> Large $5.75</p>
        </div>
      </div>
      <div class="card">
        <img class="card-img-top" src="assets/images/slushie.png" alt="Slushie">
        <div class="card-body">
          <h5 class="card-title">Slushie</h5>
          <p class="card-text">Small $3.50 <br> Medium $4.00</p>
        </div>
      </div>
      <div class="card">
        <img class="card-img-top" src="assets/images/supersundae.png" alt="Super Sundae">
        <div class="card-body">
          <h5 class="card-title">Super Sundae</h5>
          <p class="card-text">Small $4.50 <br> Large $5.25</p>
        </div>
      </div>
      <div class="card">
        <img class="card-img-top" src="assets/images/float.png" alt="Float">
        <div class="card-body">
          <h5 class="card-title">Float</h5>
          <p class="card-text">$4.50</p>
        </div>
      </div>
    </div>
    <div class="card-group">
      <div class="card">
        <img class="card-img-top" src="assets/images/hotdog.png" alt="All Beef Hotdog">
        <div class="card-body">
          <h5 class="card-title">All Beef Hotdog</h5>
          <p class="card-text">$1.75</p>
        </div>
      </div>
      <div class="card">
        <img class="card-img-top" src="assets/images/hotdogcombo.png" alt="All Beef Hotdog Combo">
        <div class="card-body">
          <h5 class="card-title">All Beef Hotdog Combo</h5>
          <p class="card-text">$6.00</p>
        </div>
      </div>
      <div class="card">
        <img class="card-img-top" src="assets/images/nacho.png" alt="Nachos and Cheese">
        <div class="card-body">
          <h5 class="card-title">Nachos and Cheese</h5>
          <p class="card-text">$3.00</p>
        </div>
      </div>
      <div class="card">
        <img class="card-img-top" src="assets/images/pretzel.png" alt="Pretzel">
        <div class="card-body">
          <h5 class="card-title">Pretzel</h5>
          <p class="card-text">$2.50<br> With Cheese $3.00</p>
        </div>
      </div>
    </div>
    <div class="card-group">
      <div class="card">
        <img class="card-img-top" src="assets/images/chips.png" alt="Chips">
        <div class="card-body">
          <h5 class="card-title">Chips</h5>
          <p class="card-text">$1.50</p>
        </div>
      </div>
      <div class="card">
        <img class="card-img-top" src="assets/images/pickle.png" alt="Pickle">
        <div class="card-body">
          <h5 class="card-title">Pickle</h5>
          <p class="card-text">$1.25</p>
        </div>
      </div>
      <div class="card">
        <img class="card-img-top" src="assets/images/toppings.png" alt="Toppings for Hotdog">
        <div class="card-body">
          <h5 class="card-title">Toppings for Hotdog</h5>
          <p class="card-text">$0.50</p>
        </div>
      </div>
      <div class="card">
        <img class="card-img-top" src="assets/images/drinks.png" alt="Drinks">
        <div class="card-body">
          <h5 class="card-title">Drinks</h5>
          <p class="card-text">$1.50</p>
        </div>
      </div>
    </div>
  </div>

  <div id="flavorDisplayJ" class="jumbotron">
    <h1 class="text-center">Random Flavor Generator</h1>
  </div>
  <div id="flavorDisplay" class="picker">
    <h3 id="flavorDisplayP" class="text-center">New Flavor Here</h3>
    <div class="picker-button text-center">
      <button type="submit" class="btn btn-primary left-btn" onclick="newFlavor()">New Flavor</button>
      <button hidden id = "flavorDisplayB" type="submit" class="btn btn-primary right-btn" onclick="scrollFlavor()"></button>
    </div>
  </div>
  <div class="jumbotron">
    <h1 class="text-center">Shaved Ice Flavors</h1>
  </div>
  <div class="deck">
    <div class="text-center card-flip" id="card" *ngFor="let flavor of flavors">
      <div class="flip">
        <div class="front">
          <div class="card">
            <div class="card-body">
              <h5 class="card-text">{{flavor.name}}</h5>
            </div>
          </div>
        </div>
        <div class="back">
          <div class="card">
            <div class="card-body">
              <h5 class="card-text">{{flavor.flavors}}</h5>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<button id="to-top" type="submit" class="btn btn-primary scroll" onclick="scrollView()">Flavor Generator</button>
