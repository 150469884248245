<div class="container">
  <!-- <form (ngSubmit)="processForm()">
    <div class="form-group">
      <label for="name">Your Name</label>
      <input type="name" name="name" [(ngModel)]="name" class="form-control" id="name" aria-describedby="nameHelp" placeholder="Enter Name">
    </div>
    <div class="form-group">
      <label for="bdaymonth">Date of Birthday:</label>
      <input type="text" name="bdaymonth" [(ngModel)]="bdaymonth" id="bdaymonth" class="form-control birthday" placeholder="Birthday">
      <small class="form-text text-muted">Format: XX/XX/XXXX</small>
    </div>
    <div class="form-group">
      <label for="phone">Enter your phone number:</label>
      <input type="tel" name="phone" [(ngModel)]="phone" class="form-control" id="phone" aria-describedby="phoneHelp" placeholder="Enter Phone Number">
      <small class="form-text text-muted">Format: XXX-XXX-XXXX</small>
    </div>
    <div class="form-group">
      <label for="exampleInputEmail1">Email Address</label>
      <input type="email" name="email" [(ngModel)]="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Email">
    </div>
    <div class="form-group">
      <label for="message">List Work Experience</label>
      <textarea class="form-control" name="work" [(ngModel)]="work" id="work" rows="3"></textarea>
    </div>
    <button type="submit" class="btn btn-primary">Submit</button>
  </form> -->

  <div class="jumbotron">
    <h1 class="text-center">Sno Biz Employee Application</h1>
    <p class="jumbo-text text-center">Option 1: Scan and send to snobizicedup@gmail.com or drop off at Sno Biz in Parkersburg</p>
    <p class="jumbo-text text-center">Option 2: Print and mail to Sno Biz 230 Park Center Drive Parkersburg, WV 26101</p>
    <p class="jumbo-text text-center">Option 3: Print and drop off to Sno Biz 230 Park Center Drive Parkersburg, WV 26101</p>
  </div>

  <ngx-extended-pdf-viewer [src]="'assets/example.pdf'" useBrowserLocale="true" height="80vh"></ngx-extended-pdf-viewer>
  
</div>
