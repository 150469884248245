import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-content-view',
  templateUrl: './content-view.component.html',
  styleUrls: ['./content-view.component.scss', '../app.component.scss']
})
export class ContentViewComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }
}
