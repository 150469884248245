<div class="container">

    <div class="jumbotron">
        <h1 class="text-center">Welcome to MOV Sno Biz</h1>
    </div>

    <div class="card-group">
        <div class="card">
            <div class="card-header text-center">
                <h3>Parkersburg</h3>
            </div>
            <div class="card-body">
                <p class="card-text text-center">230, Park Center Drive, Parkersburg, WV 26101</p>
            </div>
        </div>
        <div class="card">
            <div class="card-header text-center">
                <h3>Mobile Routes</h3>
            </div>
            <div class="card-body">
                <p class="card-text text-center">Look at our <a id="link" href="https://www.facebook.com/SNOICEDUP/" target="_blank">Facebook</a> page for information about our routes!</p>
            </div>
        </div>
    </div>

    <div class="jumbotron">
        <h1 class="text-center">MOV Sno Biz Hours</h1>
    </div>

    <div class="card-group">
        <div class="card">
            <div class="card-header text-center">
                <h5>Monday</h5>
            </div>
            <div class="card-body">
                <p class="card-text text-center">3 pm - 8 pm</p>
            </div>
        </div>
        <div class="card">
            <div class="card-header text-center">
                <h5>Tuesday</h5>
            </div>
            <div class="card-body">
                <p class="card-text text-center">3 pm - 8 pm</p>
            </div>
        </div>
        <div class="card">
            <div class="card-header text-center">
                <h5>Wednesday</h5>
            </div>
            <div class="card-body">
                <p class="card-text text-center">3 pm - 8 pm</p>
            </div>
        </div>
        <div class="card">
            <div class="card-header text-center">
                <h5>Thursday</h5>
            </div>
            <div class="card-body">
                <p class="card-text text-center">3 pm - 8 pm</p>
            </div>
        </div>
        <div class="card">
            <div class="card-header text-center">
                <h5>Friday</h5>
            </div>
            <div class="card-body">
                <p class="card-text text-center">3 pm - 8 pm</p>
            </div>
        </div>
        <div class="card">
            <div class="card-header text-center">
                <h5>Saturday</h5>
            </div>
            <div class="card-body">
                <p class="card-text text-center">11 pm - 8 pm</p>
            </div>
        </div>
        <div class="card">
            <div class="card-header text-center">
                <h5>Sunday</h5>
            </div>
            <div class="card-body">
                <p class="card-text text-center">12 pm - 8 pm</p>
            </div>
        </div>
    </div>

    <!-- <div class="card-group">
        <div class="card">
            <div class="card-header text-center">
                <h3>Hours</h3>
            </div>
            <div class="card-body">
                <p class="card-text">
                    Monday: 11 am - 10 pm
                    <br>
                    Tuesday: 11 am - 10 pm
                    <br>
                    Wednesday: 11 am - 10 pm
                    <br>
                    Thursday: 11 am - 10 pm
                    <br>
                    Friday: 11 am - 10 pm
                    <br>
                    Saturday: 11 am - 10 pm
                    <br>
                    Sunday: 12:00 am - 8:00 pm
                </p>
            </div>
        </div>
        <div class="card">
            <div class="card-header text-center">
                <h3>Location</h3>
            </div>
            <div class="card-body">
                <p class="card-text">Our Menu is comprised of more than 50 shaved ice flavors, as well as hotdogs and other hot treats.</p>
                <a class="link" routerLink="/menu-page" routerLinkActive="active">Go to Menu now</a>
            </div>
        </div>
        <div class="card">
            <div class="card-header text-center">
                <h3>Apply Today</h3>
            </div>
            <div class="card-body">
                <p class="card-text">Looking for a summer part time job? Visit the Apply Today page and print off our application to get started!</p>
                <a class="link" routerLink="/application-page" routerLinkActive="active">Go to Apply Today now</a>
            </div>
        </div>
    </div> -->

    <div class="jumbotron">
        <h1 class="text-center">Navigating MOV Sno Biz</h1>
    </div>

    <div class="card-group">
        <div class="card">
            <div class="card-header text-center">
                <h3>Events</h3>
            </div>
            <div class="card-body">
                <p class="card-text">Visit the Events page to see all up coming events and where we will be!</p>
                <a class="link" routerLink="/event-page" routerLinkActive="active">Go to Events now</a>
            </div>
        </div>
        <div class="card">
            <div class="card-header text-center">
                <h3>Menu</h3>
            </div>
            <div class="card-body">
                <p class="card-text">Our Menu is comprised of more than 50 shaved ice flavors, as well as hotdogs and other hot treats.</p>
                <a class="link" routerLink="/menu-page" routerLinkActive="active">Go to Menu now</a>
            </div>
        </div>
        <div class="card">
            <div class="card-header text-center">
                <h3>Apply Today</h3>
            </div>
            <div class="card-body">
                <p class="card-text">Looking for a summer part time job? Visit the Apply Today page and print off our application to get started!</p>
                <a class="link" routerLink="/application-page" routerLinkActive="active">Go to Apply Today now</a>
            </div>
        </div>
    </div>

  <!-- <div id="myCarousel" class="carousel slide" data-ride="carousel">
    <ol class="carousel-indicators">
        <li data-target="#myCarousel" data-slide-to="0" class="active"></li>
        <li data-target="#myCarousel" data-slide-to="1"></li>
        <li data-target="#myCarousel" data-slide-to="2"></li>
    </ol>
    <div class="carousel-inner">
        <div class="carousel-item active">
            <img src="images/slide1.png" alt="First Slide">
        </div>
        <div class="carousel-item">
            <img src="images/slide2.png" alt="Second Slide">
        </div>
        <div class="carousel-item">
            <img src="images/slide3.png" alt="Third Slide">
        </div>
    </div>
    <a class="carousel-control-prev" href="#myCarousel" data-slide="prev">
        <span class="carousel-control-prev-icon"></span>
    </a>
    <a class="carousel-control-next" href="#myCarousel" data-slide="next">
        <span class="carousel-control-next-icon"></span>
    </a>
  </div> -->
</div> 
